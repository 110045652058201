import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";

SwiperCore.use([Pagination, Navigation, Autoplay]);

@Component({
    selector: "nerri-about",
    templateUrl: "about.template.html",
    styleUrls: ["./about.style.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AboutComponent implements OnInit {
    constructor(
        private translateService: TranslateService,
        private title: Title
    ) {}

    public async ngOnInit() {
        this.title.setTitle(
            await this.translateService.get("pages.about.title").toPromise()
        );
    }
}
