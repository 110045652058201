import { NgModule } from "@angular/core";
import { GuidesComponent } from "./guides.component";
import { NavbarModule } from "../../components/navbar/navbar.module";
import { FooterModule } from "../../components/footer/footer.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    imports: [
        NavbarModule,
        FooterModule,
        FlexLayoutModule,
        TranslateModule
    ],
    exports: [GuidesComponent],
    declarations: [GuidesComponent],
    providers: [],
})
export class GuidesModule {
}
