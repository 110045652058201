import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { TransferState } from "@angular/platform-browser";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";

import { TranslatesBrowserLoaderService } from "./translates-browser-loader.service";

export function translateStaticLoader(
    http: HttpClient,
    transferState: TransferState
): TranslatesBrowserLoaderService {
    return new TranslatesBrowserLoaderService(
        "/assets/i18n/",
        ".json",
        transferState,
        http
    );
}

@NgModule({
    imports: [
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateStaticLoader,
                deps: [HttpClient, TransferState],
            },
        }),
    ],
})
export class TranslatesBrowserModule {}
