import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NavigationStart, Router } from "@angular/router";
import { NavigationService } from "../../providers/navigation.service";
import { LOCAL_STORAGE_TOKEN } from "../../providers/local-storage.provider";

@Component({
    selector: "nerri-navbar",
    templateUrl: "navbar.template.html",
    styleUrls: ["./navbar.style.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit {
    public navOpen = false;

    public get currentLang(): string {
        return this.translateService.currentLang;
    }

    constructor(
        @Inject(LOCAL_STORAGE_TOKEN) private localStorage: Storage,
        private navigationService: NavigationService,
        private router: Router,
        private translateService: TranslateService
    ) {}

    public ngOnInit(): void {
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationStart) {
                this.navOpen = false;
            }
        });
    }

    public openNav() {
        this.navOpen = true;
    }

    public closeNav() {
        this.navOpen = false;
    }

    public toggleLang() {
        if (this.translateService.getDefaultLang() !== "fr") {
            this.localStorage.setItem("lang", "fr");
            this.translateService.setDefaultLang("fr");
            this.translateService.use("fr");
        } else {
            this.localStorage.setItem("lang", "en");
            this.translateService.setDefaultLang("en");
            this.translateService.use("en");
        }
    }
}
