import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";

@Component({
    selector: "nerri-guides",
    templateUrl: "guides.template.html",
    styleUrls: ["./guides.style.scss"],
    encapsulation: ViewEncapsulation.None
})
export class GuidesComponent implements OnInit {
    constructor(
        private translateService: TranslateService,
        private title: Title
    ) {}

    public async ngOnInit() {
        this.title.setTitle(
            await this.translateService.get("pages.guides.title").toPromise()
        );
    }
}
