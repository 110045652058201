import { NgModule } from "@angular/core";
import { FooterComponent } from "./footer.component";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@NgModule({
    imports: [
        RouterModule,
        FlexLayoutModule,
        TranslateModule,
        MatButtonModule,
        MatIconModule,
        FontAwesomeModule,
    ],
    exports: [FooterComponent],
    declarations: [FooterComponent],
    providers: [],
})
export class FooterModule {}
