import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ViewElementDirective } from "./view-element.directive";

@NgModule({
    imports: [CommonModule],
    declarations: [ViewElementDirective],
    exports: [ViewElementDirective]
})
export class DirectivesModule {
}
