<div class="container">
    <div class="content">
        <div class="nav">
            <nerri-navbar></nerri-navbar>
        </div>
        <div class="hero">
            <div class="hero-background-container">
                <div class="hero-bubbles-container">
                    <div class="hero-bubbles-grid">
                        <div class="hero-bubble"></div>
                        <div class="hero-bubble"></div>
                        <div class="hero-bubble"></div>
                        <div class="hero-bubble"></div>
                        <div class="hero-bubble"></div>
                        <div class="hero-bubble"></div>
                    </div>
                </div>
                <div class="hero-background pattern-zigzag-sm"></div>
            </div>
            <div
                    class="hero-content-container section"
                    fxLayoutAlign="start start"
                    fxLayout.lt-md="column-reverse"
            >
                <div
                        fxFlex="50"
                        fxLayout="column"
                        fxLayoutGap="32px"
                        fxFlex.lt-md="100%"
                >
                    <div
                            class="hero-title"
                            [innerHTML]="'pages.home.hero.title' | translate"
                    ></div>
                    <div class="hero-description">
                        {{ "pages.home.hero.description" | translate }}
                    </div>
                    <div class="hero-cta">
                        <a
                                mat-flat-button
                                color="accent"
                                href="https://calendly.com/oclair"
                        >
                            {{ "general.demo" | translate }}&nbsp;
                            <mat-icon>chevron_right</mat-icon>
                        </a>
                    </div>
                </div>
                <div fxFlex="50" fxFlex.lt-md="100%" class="hero-image">
                    <img
                            class="hero-web-ss"
                            [src]="'../../../assets/images/screenshots/' + currentLang + '/system-view.png'"
                    />
                </div>
            </div>
            <div class="hero-sub mt-100" fxLayoutAlign="center center">
                <button mat-icon-button class="scroll-button" (click)="clickScrollDown()">
                    <fa-icon [icon]="['fas', 'arrow-down']" size="2x"></fa-icon>
                </button>
            </div>
        </div>
        <div class="section-container take-control" viewElement="content">
            <div class="section">
                <h1
                        class="text-center"
                        [innerHTML]="'pages.home.take_control.title' | translate"
                ></h1>
                <p
                        class="text-center"
                        [innerHTML]="'pages.home.take_control.subtitle' | translate"
                ></p>
                <div
                        class="mt-128"
                        fxLayoutAlign="start start"
                        fxLayout.lt-md="column"
                        fxLayoutGap="32px"
                        fxLayoutAlign.lt-md="start center"
                >
                    <div
                            class="p-item"
                            fxFlex="1/3"
                            fxLayout="column"
                            fxLayoutAlign="start center"
                            fxLayoutGap="32px"
                    >
                        <div
                                fxFlex="150px"
                                class="bubble-icon bi-blue"
                                fxLayoutAlign="center center"
                        >
                            <fa-icon
                                    [icon]="['fad', 'comments']"
                                    size="3x"
                            ></fa-icon>
                        </div>
                        <div class="overview-text">
                            <h1>
                                {{ "pages.home.take_control.facilitate.title" |
                                translate }}
                            </h1>
                            <p>
                                {{
                                "pages.home.take_control.facilitate.description"
                                    | translate }}
                            </p>
                        </div>
                    </div>
                    <div
                            class="p-item"
                            fxFlex="1/3"
                            fxLayout="column"
                            fxLayoutAlign="start center"
                            fxLayoutGap="32px"
                    >
                        <div
                                fxFlex="150px"
                                class="bubble-icon bi-blue"
                                fxLayoutAlign="center center"
                        >
                            <fa-icon
                                    [icon]="['fad', 'server']"
                                    size="3x"
                            ></fa-icon>
                        </div>
                        <div class="overview-text">
                            <h1>
                                {{ "pages.home.take_control.centralize.title" |
                                translate }}
                            </h1>
                            <p>
                                {{
                                "pages.home.take_control.centralize.description"
                                    | translate }}
                            </p>
                        </div>
                    </div>
                    <div
                            class="p-item"
                            fxFlex="1/3"
                            fxLayout="column"
                            fxLayoutAlign="center center"
                            fxLayoutGap="32px"
                    >
                        <div
                                fxFlex="150px"
                                class="bubble-icon bi-blue"
                                fxLayoutAlign="center center"
                        >
                            <fa-icon
                                    [icon]="['fad', 'bullseye-arrow']"
                                    size="3x"
                            ></fa-icon>
                        </div>
                        <div class="overview-text">
                            <h1>
                                {{ "pages.home.take_control.optimize.title" |
                                translate }}
                            </h1>
                            <p>
                                {{
                                "pages.home.take_control.optimize.description" |
                                    translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-container">
            <div class="section">
                <h1
                        class="text-center"
                        [innerHTML]="'pages.home.overview.title' | translate"
                ></h1>
                <p
                        class="text-center"
                        [innerHTML]="'pages.home.overview.subtitle' | translate"
                ></p>
            </div>
        </div>
        <div class="section-container overview">
            <div class="section-background-container">
                <div class="section-background overview-background"></div>
            </div>
            <div class="section">
                <div
                        class="mt-64"
                        fxLayout.lt-md="column-reverse"
                        fxLayoutAlign="start center"
                        fxLayoutGap="32px"
                >
                    <div fxFlex.lt-md="1 1 0%" fxFlex="70">
                        <h1>
                            {{ "pages.home.overview.card.title" | translate }}
                        </h1>
                        <ul>
                            <li
                                    [innerHTML]="'pages.home.overview.card.statement_1' | translate"
                            ></li>
                            <li
                                    [innerHTML]="'pages.home.overview.card.statement_2' | translate"
                            ></li>
                            <li
                                    [innerHTML]="'pages.home.overview.card.statement_3' | translate"
                            ></li>
                            <li
                                    [innerHTML]="'pages.home.overview.card.statement_4' | translate"
                            ></li>
                            <li
                                    [innerHTML]="'pages.home.overview.card.statement_5' | translate"
                            ></li>
                        </ul>

                        <div class="mt-32">
                            <a
                                    mat-flat-button
                                    color="accent"
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://calendly.com/oclair"
                            >
                                {{ "general.demo" | translate }}
                                <mat-icon>chevron_right</mat-icon>
                            </a>
                        </div>
                    </div>
                    <div
                            fxFlex.lt-md="260px"
                            fxFlex="30"
                            class="collaboration-image"
                    >
                        <img
                                class="collaboration-ss"
                                [src]="'../../../assets/images/collaboration.jpg'"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="section-container features">
            <div class="section">
                <h1
                        class="text-center my-64"
                        [innerHTML]="'pages.home.features.title' | translate"
                ></h1>
                <div
                        fxLayout="column"
                        fxLayoutAlign="start center"
                        fxLayoutGap="64px"
                >
                    <div
                            fxLayout="row"
                            fxLayout.lt-md="column"
                            fxLayoutAlign="stretch"
                            fxLayoutGap="32px"
                    >
                        <div
                                class="feature-container feature-blue"
                                fxLayout="column"
                                fxLayoutAlign="space-between start"
                                fxFlex.lt-md="100%"
                                fxFlex="1/3"
                        >
                            <div class="feature-icon">
                                <fa-icon
                                        [icon]="['fas', 'list']"
                                        size="1x"
                                ></fa-icon>
                            </div>
                            <h3>
                                {{ "pages.home.features.feature_1" | translate
                                }}
                            </h3>
                        </div>
                        <div
                                class="feature-container feature-blue"
                                fxLayout="column"
                                fxLayoutAlign="space-between start"
                                fxFlex.lt-md="100%"
                                fxFlex="1/3"
                        >
                            <div class="feature-icon">
                                <fa-icon
                                        [icon]="['fas', 'share-alt']"
                                        size="1x"
                                ></fa-icon>
                            </div>
                            <h3>
                                {{ "pages.home.features.feature_2" | translate
                                }}
                            </h3>
                        </div>
                        <div
                                class="feature-container feature-blue"
                                fxLayout="column"
                                fxLayoutAlign="space-between start"
                                fxFlex.lt-md="100%"
                                fxFlex="1/3"
                        >
                            <div class="feature-icon">
                                <fa-icon
                                        [icon]="['fas', 'balance-scale']"
                                        size="1x"
                                ></fa-icon>
                            </div>
                            <h3>
                                {{ "pages.home.features.feature_3" | translate
                                }}
                            </h3>
                        </div>
                    </div>
                    <div
                            fxLayout="row"
                            fxLayout.lt-md="column"
                            fxLayoutAlign="stretch"
                            fxLayoutGap="32px"
                    >
                        <div
                                class="feature-container feature-green"
                                fxLayout="column"
                                fxLayoutAlign="space-between start"
                                fxFlex.lt-md="100%"
                                fxFlex="1/3"
                        >
                            <div class="feature-icon">
                                <fa-icon
                                        [icon]="['fas', 'file-export']"
                                        size="1x"
                                ></fa-icon>
                            </div>
                            <h3>
                                {{ "pages.home.features.feature_4" | translate
                                }}
                            </h3>
                        </div>
                        <div
                                class="feature-container feature-green"
                                fxLayout="column"
                                fxLayoutAlign="space-between start"
                                fxFlex.lt-md="100%"
                                fxFlex="1/3"
                        >
                            <div class="feature-icon">
                                <fa-icon
                                        [icon]="['fas', 'filter']"
                                        size="1x"
                                ></fa-icon>
                            </div>
                            <h3>
                                {{ "pages.home.features.feature_5" | translate
                                }}
                            </h3>
                        </div>
                        <div
                                class="feature-container feature-green"
                                fxLayout="column"
                                fxLayoutAlign="space-between start"
                                fxFlex.lt-md="100%"
                                fxFlex="1/3"
                        >
                            <div class="feature-icon">
                                <fa-icon
                                        [icon]="['fas', 'mail-bulk']"
                                        size="1x"
                                ></fa-icon>
                            </div>
                            <h3>
                                {{ "pages.home.features.feature_6" | translate
                                }}
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="mt-64" fxLayoutAlign="center center">
                    <a
                            mat-flat-button
                            color="accent"
                            target="_blank"
                            rel="noreferrer"
                            href="https://calendly.com/oclair"
                    >
                        {{ "general.demo" | translate }}
                        <mat-icon>chevron_right</mat-icon>
                    </a>
                </div>
            </div>
        </div>
        <div class="section-container filters">
            <div class="section-background-container">
                <div class="section-background filters-background"></div>
            </div>
            <div class="section">
                <div
                        class="mt-64"
                        fxLayout.lt-md="column-reverse"
                        fxLayoutAlign="start center"
                        fxLayoutGap="32px"
                >
                    <div fxFlex.lt-md fxFlex="70">
                        <h1>{{ "pages.home.filters.title" | translate }}</h1>
                        <p>
                            {{ "pages.home.filters.description" | translate }}
                        </p>
                        <ul>
                            <li
                                    [innerHTML]="'pages.home.filters.statement_1' | translate"
                            ></li>
                            <li
                                    [innerHTML]="'pages.home.filters.statement_2' | translate"
                            ></li>
                            <li
                                    [innerHTML]="'pages.home.filters.statement_3' | translate"
                            ></li>
                            <li
                                    [innerHTML]="'pages.home.filters.statement_4' | translate"
                            ></li>
                        </ul>

                        <div class="mt-32">
                            <a
                                    mat-flat-button
                                    color="accent"
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://calendly.com/oclair"
                            >
                                {{ "general.demo" | translate }}
                                <mat-icon>chevron_right</mat-icon>
                            </a>
                        </div>
                    </div>
                    <div fxFlex.lt-md="260px" fxFlex="30" class="filters-image">
                        <img
                                class="filters-ss"
                                [src]="'../../../assets/images/filters.png'"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="section-container management">
            <div class="section">
                <div
                        class="mt-64"
                        fxLayoutAlign="start center"
                        fxLayoutGap="64px"
                        fxLayout.lt-md="column"
                >
                    <div fxFlex="50" class="management-image">
                        <img
                                class="management-ss"
                                [src]="'../../../assets/images/screenshots/' + currentLang + '/system-view.png'"
                        />
                    </div>
                    <div fxFlex="50">
                        <h1
                                [innerHTML]="'pages.home.management.title' | translate"
                        ></h1>
                        <p>
                            {{ "pages.home.management.description" | translate
                            }}
                        </p>
                        <div class="mt-32">
                            <a
                                    mat-flat-button
                                    color="accent"
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://calendly.com/oclair"
                            >
                                {{ "general.demo" | translate }}
                                <mat-icon>chevron_right</mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-container communication">
            <div class="section-background-container">
                <div class="section-background communication-background"></div>
            </div>
            <div class="section">
                <div
                        class="mt-64"
                        fxLayout.lt-md="column-reverse"
                        fxLayoutAlign="start center"
                        fxLayoutGap="32px"
                >
                    <div fxFlex.lt-md="1 1 0%" fxFlex="70">
                        <h1
                                [innerHTML]="'pages.home.communication.title' | translate"
                        ></h1>
                        <p>
                            {{ "pages.home.communication.description" |
                            translate }}
                        </p>

                        <div class="mt-32">
                            <a
                                    mat-flat-button
                                    color="accent"
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://calendly.com/oclair"
                            >
                                {{ "general.demo" | translate }}
                                <mat-icon>chevron_right</mat-icon>
                            </a>
                        </div>
                    </div>
                    <div
                            fxFlex.lt-md="370px"
                            fxFlex="30"
                            class="communication-image"
                    >
                        <img
                                class="communication-ss"
                                [src]="'../../../assets/images/communication.png'"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-64 section-container citizen">
            <div class="section-background-container">
                <div class="section-background citizen-background"></div>
            </div>
            <div class="section">
                <div
                        class="mt-64"
                        fxLayout.lt-md="column"
                        fxLayoutAlign="start center"
                        fxLayoutGap="64px"
                >
                    <div
                            fxFlex.lt-md="1 1 0%"
                            fxFlex="30"
                            class="citizen-image"
                    >
                        <img
                                class="citizen-ss"
                                src="../../../assets/images/undraw_at_home_octe.svg"
                        />
                    </div>
                    <div fxFlex.lt-md="1 1 0%" fxFlex="70">
                        <h1
                                [innerHTML]="'pages.home.citizen.title' | translate"
                        ></h1>
                        <h3 class="mt-32">
                            {{ "pages.home.citizen.subtitle" | translate }}
                        </h3>
                        <div
                                class="mt-32"
                                fxLayoutAlign="start start"
                                fxLayout.lt-md="column"
                                fxLayoutGap="32px"
                                fxLayoutAlign.lt-md="start center"
                        >
                            <ul class="citizen-list">
                                <li>
                                    <fa-duotone-icon
                                            class="emphasis-2"
                                            [icon]="['fad', 'check-circle']"
                                            size="2x"
                                    ></fa-duotone-icon>
                                    {{ "pages.home.citizen.statement_1" |
                                    translate }}
                                </li>
                                <li>
                                    <fa-duotone-icon
                                            class="emphasis-2"
                                            [icon]="['fad', 'check-circle']"
                                            size="2x"
                                    ></fa-duotone-icon>
                                    {{ "pages.home.citizen.statement_2" |
                                    translate }}
                                </li>
                                <li>
                                    <fa-duotone-icon
                                            class="emphasis-2"
                                            [icon]="['fad', 'check-circle']"
                                            size="2x"
                                    ></fa-duotone-icon>
                                    {{ "pages.home.citizen.statement_3" |
                                    translate }}
                                </li>
                                <li>
                                    <fa-duotone-icon
                                            class="emphasis-2"
                                            [icon]="['fad', 'check-circle']"
                                            size="2x"
                                    ></fa-duotone-icon>
                                    {{ "pages.home.citizen.statement_4" |
                                    translate }}
                                </li>
                                <li>
                                    <fa-duotone-icon
                                            class="emphasis-2"
                                            [icon]="['fad', 'check-circle']"
                                            size="2x"
                                    ></fa-duotone-icon>
                                    {{ "pages.home.citizen.statement_5" |
                                    translate }}
                                </li>
                                <li>
                                    <fa-duotone-icon
                                            class="emphasis-2"
                                            [icon]="['fad', 'check-circle']"
                                            size="2x"
                                    ></fa-duotone-icon>
                                    {{ "pages.home.citizen.statement_6" |
                                    translate }}
                                </li>
                                <li>
                                    <fa-duotone-icon
                                            class="emphasis-2"
                                            [icon]="['fad', 'check-circle']"
                                            size="2x"
                                    ></fa-duotone-icon>
                                    {{ "pages.home.citizen.statement_7" |
                                    translate }}
                                </li>
                            </ul>
                        </div>

                        <div class="mt-32">
                            <a
                                    mat-flat-button
                                    color="accent"
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://calendly.com/oclair"
                            >
                                {{ "general.demo" | translate }}
                                <mat-icon>chevron_right</mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-container numbers">
            <div class="section">
                <h1
                        class="text-center my-64"
                        [innerHTML]="'pages.home.numbers.title' | translate"
                ></h1>
                <div
                        class="numbers-container"
                        fxLayout="column"
                        fxLayoutAlign="start center"
                >
                    <div
                            class="w-100-p"
                            fxLayout="row"
                            fxLayout.lt-md="column"
                            fxLayoutAlign="stretch"
                    >
                        <div
                                id="number-1"
                                class="number-container"
                                fxFlex="50"
                                fxLayout="column"
                                fxLayoutAlign="center center"
                        >
                            <div class="number-title">
                                87%
                            </div>
                            <h3>
                                {{ "pages.home.numbers.number_1" | translate }}
                            </h3>
                        </div>
                        <div
                                id="number-2"
                                class="number-container"
                                fxFlex="50"
                                fxLayout="column"
                                fxLayoutAlign="center center"
                        >
                            <div class="number-title">
                                86%
                            </div>
                            <h3>
                                {{ "pages.home.numbers.number_2" | translate }}
                            </h3>
                        </div>
                    </div>
                    <div
                            class="w-100-p"
                            fxLayout="row"
                            fxLayout.lt-md="column"
                            fxLayoutAlign="stretch"
                    >
                        <div
                                id="number-3"
                                class="number-container"
                                fxFlex="50"
                                fxLayout="column"
                                fxLayoutAlign="center center"
                        >
                            <div class="number-title">
                                96%
                            </div>
                            <h3>
                                {{ "pages.home.numbers.number_3" | translate }}
                            </h3>
                        </div>
                        <div
                                id="number-4"
                                class="number-container"
                                fxFlex="50"
                                fxLayout="column"
                                fxLayoutAlign="center center"
                        >
                            <div class="number-title">
                                89%
                            </div>
                            <h3>
                                {{ "pages.home.numbers.number_4" | translate }}
                            </h3>
                        </div>
                    </div>
                    <div class="logo" fxHide.lt-md>
                        <img
                                src="../../../assets/images/logos/logo-symbol.svg"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="section-container mission">
            <div class="section-background-container">
                <div class="section-background mission-background"></div>
            </div>
            <div class="section">
                <div
                        class="mt-64"
                        fxLayout.lt-md="column-reverse"
                        fxLayoutAlign="start center"
                        fxLayoutGap="64px"
                >
                    <div fxFlex.lt-md="1 1 0%" fxFlex="70">
                        <h1
                                [innerHTML]="'pages.home.mission.title' | translate"
                        ></h1>
                        <div
                                class="mt-32"
                                fxLayoutAlign="start start"
                                fxLayout.lt-md="column"
                                fxLayoutGap="32px"
                                fxLayoutAlign.lt-md="start start"
                        >
                            <ul class="mission-list">
                                <li>
                                    <fa-duotone-icon
                                            class="emphasis-2"
                                            [icon]="['fad', 'check-circle']"
                                            size="2x"
                                    ></fa-duotone-icon>
                                    {{ "pages.home.mission.statement_1" |
                                    translate }}
                                </li>
                                <li>
                                    <fa-duotone-icon
                                            class="emphasis-2"
                                            [icon]="['fad', 'check-circle']"
                                            size="2x"
                                    ></fa-duotone-icon>
                                    {{ "pages.home.mission.statement_2" |
                                    translate }}
                                </li>
                                <li>
                                    <fa-duotone-icon
                                            class="emphasis-2"
                                            [icon]="['fad', 'check-circle']"
                                            size="2x"
                                    ></fa-duotone-icon>
                                    {{ "pages.home.mission.statement_3" |
                                    translate }}
                                </li>
                                <li>
                                    <fa-duotone-icon
                                            class="emphasis-2"
                                            [icon]="['fad', 'check-circle']"
                                            size="2x"
                                    ></fa-duotone-icon>
                                    {{ "pages.home.mission.statement_4" |
                                    translate }}
                                </li>
                                <li>
                                    <fa-duotone-icon
                                            class="emphasis-2"
                                            [icon]="['fad', 'check-circle']"
                                            size="2x"
                                    ></fa-duotone-icon>
                                    {{ "pages.home.mission.statement_5" |
                                    translate }}
                                </li>
                            </ul>
                        </div>

                        <div class="mt-32">
                            <a
                                    mat-flat-button
                                    color="accent"
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://calendly.com/oclair"
                            >
                                {{ "general.demo" | translate }}
                                <mat-icon>chevron_right</mat-icon>
                            </a>
                        </div>
                    </div>
                    <div fxFlex.lt-md="260px" fxFlex="30" class="mission-image">
                        <img
                                class="mission-ss"
                                src="../../../assets/images/environment.jpg"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="section-container team">
            <div class="section-background-container">
                <div class="section-background team-background"></div>
            </div>
            <div class="section">
                <div
                        class="mt-64"
                        fxLayout.lt-md="column"
                        fxLayoutAlign="start center"
                        fxLayoutGap="64px"
                >
                    <div fxFlex.lt-md="220px" fxFlex="50" class="team-image">
                        <img
                                class="team-ss"
                                src="../../../assets/images/team.png"
                        />
                    </div>
                    <div fxFlex.lt-md="1 1 0%" fxFlex="50">
                        <h1
                                [innerHTML]="'pages.home.team.title' | translate"
                        ></h1>
                        <div
                                class="mt-32"
                                fxLayoutAlign="start start"
                                fxLayout="column"
                                fxLayoutGap="32px"
                                fxLayoutAlign.lt-md="start start"
                        >
                            <p
                                    [innerHTML]="'pages.home.team.description_1' | translate"
                            ></p>
                            <p
                                    [innerHTML]="'pages.home.team.description_2' | translate"
                            ></p>
                            <p
                                    [innerHTML]="'pages.home.team.description_3' | translate"
                            ></p>
                            <p
                                    [innerHTML]="'pages.home.team.description_4' | translate"
                            ></p>
                        </div>

                        <div class="mt-32">
                            <a
                                    mat-flat-button
                                    color="accent"
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://calendly.com/oclair"
                            >
                                {{ "general.demo" | translate }}
                                <mat-icon>chevron_right</mat-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-container partners">
            <div class="section">
                <h1
                        class="text-center my-64"
                        [innerHTML]="'pages.home.partners.title' | translate"
                ></h1>
                <p
                        class="text-center"
                        [innerHTML]="'pages.home.partners.subtitle' | translate"
                ></p>
                <div class="partner-logos mt-64">
                    <div fxLayoutAlign="center">
                        <a
                                href="https://recursyve.io/"
                                target="_blank"
                                rel="noreferrer"
                        >
                            <img
                                    class="partner"
                                    src="../../../assets/images/logos/recursyve.png"
                            />
                        </a>
                    </div>
                    <div fxLayoutAlign="center">
                        <a
                                href="https://nordikeau.com/"
                                target="_blank"
                                rel="noreferrer"
                        >
                            <img
                                    class="partner"
                                    src="../../../assets/images/logos/nordikeau.png"
                            />
                        </a>
                    </div>
                    <div fxLayoutAlign="center">
                        <a
                                href="https://www.eurekaenvironnement.com/"
                                target="_blank"
                                rel="noreferrer"
                        >
                            <img
                                    class="partner"
                                    src="../../../assets/images/logos/eureka.png"
                            />
                        </a>
                    </div>
                    <div fxLayoutAlign="center">
                        <a
                                href="https://www.centreau.ulaval.ca/"
                                target="_blank"
                                rel="noreferrer"
                        >
                            <img
                                    class="partner"
                                    src="../../../assets/images/logos/centreau.png"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <nerri-footer></nerri-footer>
    </div>
</div>
