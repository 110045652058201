import { Component, OnInit, QueryList, ViewChildren, ViewEncapsulation } from "@angular/core";
import { GeneratedFormGroup, ngxFormGeneratorFactory, } from "@recursyve/ngx-form-generator";
import { DownloadEmailForm } from "./download-email.form";
import { HomeQuery } from "./store/home.query";
import { HomeService } from "./store/home.service";
import { TranslateService } from "@ngx-translate/core";
import { Title } from "@angular/platform-browser";
import { ViewElementDirective } from "../../directives/view-element.directive";

@Component({
    selector: "nerri-home",
    templateUrl: "home.template.html",
    styleUrls: ["./home.style.scss"],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: GeneratedFormGroup,
            useFactory: ngxFormGeneratorFactory(DownloadEmailForm),
        },
    ],
})
export class HomeComponent implements OnInit {
    @ViewChildren(ViewElementDirective)
    private viewElements: QueryList<ViewElementDirective>;

    public loading$ = this.query.selectLoading();
    public submitted$ = this.query.selectSubmitted();

    constructor(
        public formGroup: GeneratedFormGroup<DownloadEmailForm>,
        private query: HomeQuery,
        private service: HomeService,
        private translateService: TranslateService,
        private title: Title
    ) {}

    public async ngOnInit() {
        this.title.setTitle(
            await this.translateService.get("pages.home.title").toPromise()
        );
    }

    public clickDownloadPresentation() {
        if (!this.validate()) {
            return;
        }
        this.service.submitEmailForDownload(
            this.formGroup.getRawValue()?.email
        );
    }

    private validate(): boolean {
        if (this.formGroup.valid) {
            return true;
        }

        this.formGroup.markAllAsTouched();
        return false;
    }

    public get currentLang(): string {
        return this.translateService.currentLang;
    }

    public clickScrollDown() {
        const viewElement = this.viewElements.find(item => item.viewElement === "content");
        if (viewElement) {
            viewElement.scrollIntoView();
        }
    }
}
