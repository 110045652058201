<div class="container">
    <div class="content">
        <div class="nav">
            <nerri-navbar></nerri-navbar>
        </div>

        <div class="section-container">
            <div class="section">
                <h1 class="text-center mb-64" [innerHTML]="'pages.guides.guides.title' | translate"></h1>
                <div class="mt-16" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutGap="64px">
                    <div class="entry-content-page">
                        <p><em><strong>Guide pour les inspecteurs municipaux:</strong></em></p>
                        <ul>
                            <li><a href="../../../assets/documents/2018/09/Dallaire_Maxine_MEnv_2016-1.pdf" target="_blank">Gérer les installations septiques individuelles Québecoises: Ligne directrice pour un programme de gestion municipal</a></li>
                            <li><a href="http://www.mddep.gouv.qc.ca/eau/eco_aqua/cyanobacteries/guide_releve.pdf" target="_blank">Guide de réalisation d’un relevé sanitaire des dispositifs d’évacuation et de traitement des eaux usées des résidences isolées situées en bordure des lacs et des cours d’eau</a></li>
                        </ul>
                        <p><em><strong>Guide du propriétaire pour systèmes septiques:</strong></em></p>
                        <p><strong>Bio-B (Non-Certifié)</strong></p>
                        <ul>
                            <li><a href="../../../assets/documents/2019/01/Manuel-dutilisation-Bio-B-francais.pdf" target="_blank">Manuel d’utilisation Bio-B français</a></li>
                            <li><a href="../../../assets/documents/2019/01/Owners-manual-Bio-B-english.pdf" target="_blank">Owners manual Bio-B english</a></li>
                        </ul>
<!--                        <p><strong>Biofiltre Waterloo</strong></p>-->
<!--                        <ul>-->
<!--                            <li><a href="http://biofiltre-waterloo.com/wp-content/uploads/Guide-du-propri%C3%A9taire-Biofiltre-Waterloo-v1.pdf" target="_blank" rel="noopener">Guide du propriétaire</a></li>-->
<!--                        </ul>-->
                        <p><strong>Bionest</strong></p>
                        <ul>
                            <li><a href="https://www.bionest-tech.com/QC-fr/medias-documentation-liste/3/documentation.html" target="_blank" rel="noopener">Guide d’utilisation</a></li>
                        </ul>
                        <p><strong>Ecobox (Non-certifié)</strong></p>
                        <ul>
                            <li><a href="../../../assets/documents/2018/08/ECOBOX-Guide-du-propriétaire-EB1260-EB1440-0310.pdf" target="_blank" rel="noopener">Guide du propriétaire – Modèle ECOBOX EB1260 / EB1440 (0310)</a></li>
<!--                            <li><a href="https://biofiltra.ca/wp-content/uploads/2018/08/ECOBOX-Guide-du-proprietaire-Modele-EB2160-_0509_.pdf" target="_blank" rel="noopener">Guide du propriétaire – Modèle Ecobox EB2160</a></li>-->
<!--                            <li><a href="https://biofiltra.ca/wp-content/uploads/2018/08/ECOBOX-Guide-iinstallation-Modele-EB2160-_0509_.pdf" target="_blank" rel="noopener">Guide d’installation – Modèle Ecobox EB2160</a></li>-->
<!--                            <li><a href="https://biofiltra.ca/wp-content/uploads/2018/08/ECOBOX-Guide-dutilisation-depannage-entretien-re%CC%81paration-Modele-EB2160-_0509_.pdf" target="_blank" rel="noopener">Guide d’utilisation, d’entretien, de dépannage et de réparation – Modèle Ecobox EB2160</a></li>-->
                        </ul>
                        <p><strong>Ecoflo</strong></p>
                        <ul>
                            <li><a href="http://www.biofiltreecoflo.com/media/911/livret-du-proprietaire-ecoflo-qcfr.pdf" target="_blank" rel="noopener">Livret du propriétaire</a></li>
                        </ul>
                        <p><strong>Écophyltre</strong></p>
                        <ul>
                            <li><a href="../../../assets/documents/2020/03/ECOPHYLTRE-Guide-du-Proprietaire.pdf" target="_blank">Guide du propriétaire&nbsp;</a></li>
                        </ul>
                        <p><strong>Enviroseptic</strong></p>
                        <ul>
                            <li><a href="https://enviroseptic.fr/wp-content/uploads/2020/02/Guide-usager-etanche.pdf" target="_blank" rel="noopener">Guide de l’usager</a></li>
                        </ul>
<!--                        <p><strong>Hydro-Kinetic</strong></p>-->
<!--                        <ul>-->
<!--                            <li><a href="https://www.enviro-step.ca/docs/hydro-kinetic_guide-assemblage-installation-utilisation.pdf" target="_blank" rel="noopener">Guide d’assemblage, d’installation et d’utilisation</a></li>-->
<!--                        </ul>-->
                    </div>
                </div>
            </div>
        </div>

        <div class="section-container">
            <div class="section">
                <h1 class="text-center mb-64" [innerHTML]="'pages.guides.documents.title' | translate"></h1>
                <div class="mt-16" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutGap="64px">
                    <div class="entry-content-page">
<!--                        <p><strong>AESEQ</strong></p>-->
<!--                        <ul>-->
<!--                            <li><a href="http://aeseq.com/pdf/depliant_eaux_usees_aeseq_2013_04_18.pdf" target="_blank"-->
<!--                                   rel="noopener noreferrer">L’essentiel à savoir sur votre installation septique</a></li>-->
<!--                        </ul>-->
                        <p><strong>Cadre général d’application des sanctions administratives pécuniaires&nbsp;</strong></p>
                        <ul>
                            <li>
                                <a href="../../../assets/documents/2020/04/CADRE-GENERAL-DAPPLICATION-DES-SANCTIONS-ADMINISTRATIVES-PECUNIAIRES.pdf"
                                   target="_blank">Orientations et&nbsp; critères généraux guidant l’application du régime de sanctions
                                    administratives pécuniaires.</a></li>
                        </ul>
                        <p><strong>Crédit d’impôt pour la mise aux normes d’installations d’assainissement des eaux usées
                            résidentielles</strong></p>
                        <ul>
                            <li>
                                <a href="https://www.revenuquebec.ca/fr/citoyens/credits-dimpot/credit-dimpot-pour-mise-aux-normes-dinstallations-dassainissement-des-eaux-usees-residentielles/">Crédit
                                    d’impôt pour la mise aux normes d’installations d’assainissement des eaux usées résidentielles</a></li>
                        </ul>
                        <div><strong>OClair Environnement</strong></div>
                        <ul>
                            <li>
                                <a href="../../../assets/documents/2020/04/NERRI-Liste-des-microorganismes-pathogènes-dans-les-eaux-usées-Avril-2020.pdf"
                                   target="_blank">NERRI-Liste des microorganismes pathogènes dans les eaux usées Avril 2020</a></li>
                        </ul>
                        <p><strong>Diagnostic d’installations septiques non conformes: mieux vaut prévenir que guérir!</strong></p>
                        <ul>
                            <li>
                                <a href="../../../assets/documents/2019/02/Diagnostic-dinstallations-septiques-non-conformes-mieux-vaut-prevenir-que-guerir.pdf"
                                   target="_blank">Diagnostic d’installations septiques non conformes: mieux vaut prévenir que guérir!</a>&nbsp;
<!--                                <a href="https://legacy.nerri.ca/collaborateurs/">Auteur de l’article: Jean-Sébastien Grenier, Ing., MBA</a></li>-->
                        </ul>
                        <p><strong>Directive sur le traitement des manquements à la législation environnementale</strong></p>
                        <ul>
                            <li>
                                <a href="../../../assets/documents/2020/04/Directive-sur-le-traitement-des-manquements-a-la-legislation-environnementale.pdf"
                                   target="_blank">Directive sur le traitement des manquements à la législation environnementale (Voir
                                    Tableau p.17)</a></li>
                        </ul>
                        <p><strong>Droit acquis en matière de réglementation municipale en environnement</strong></p>
                        <ul>
                            <li>
                                <a href="../../../assets/documents/2018/10/Droit-acquis-en-matiere-de-reglementation-municipale-en-environnement.pdf"
                                   target="_blank" rel="noopener noreferrer">Droit acquis en matière de réglementation municipale en
                                    environnement</a></li>
                        </ul>
                        <p><strong>Est-ce possible d’aménager une installation septique en conformité avec le Règlement Q-2, r. 22 dans une
                            plaine inondable?</strong></p>
                        <ul>
                            <li>
                                <a href="../../../assets/documents/2019/07/Est-ce-possible-damenager-une-installation-septique-en-conformite-avec-la-loi-Q-2r.22.pdf"
                                   target="_blank">Est-ce possible d’aménager une installation septique en conformité avec la loi
                                    Q-2,r.22</a>
                        </ul>
                        <p><strong>La gestion des eaux usées d’ateliers mécaniques et de lave-autos en assainissement décentralisé</strong>
                        </p>
                        <ul>
                            <li>
                                <a href="../../../assets/documents/2019/06/Gestion-des-eaux-usees-datelier-mecaniques-et-de-laves-autos.pdf"
                                   target="_blank">Gestion des eaux usées d’atelier mécaniques et de laves-autos en assainissement
                                    décentralisé</a></li>
                        </ul>
                        <p><strong>L’inspecteur municipal et les défis de la gestion globale de l’eau en milieu décentralisé</strong></p>
                        <ul>
                            <li>
                                <a href="../../../assets/documents/2019/02/L’inspecteur-municipal-et-les-defis-de-la-gestion-globale-de-l’eau-en-milieu-decentralise.pdf"
                                   target="_blank">L’inspecteur municipal et les défis de la gestion globale de l’eau en milieu
                                    décentralisé </a>
                        </ul>
                        <p><strong>Loi modifiant la Loi sur la qualité de l’environnement afin d’en renforcer le respect</strong></p>
                        <ul>
                            <li><a href="http://www.environnement.gouv.qc.ca/lqe/renforcement/instauration-systeme-pecuniaire.htm">Quelle
                                est la catégorisation des manquements et quels sont les montants des sanctions administratives pécuniaires
                                qui y sont rattachées?</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <nerri-footer></nerri-footer>
    </div>
</div>
