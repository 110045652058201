<div class="container">
    <div class="content">
        <div class="nav">
            <nerri-navbar></nerri-navbar>
        </div>
        <div class="hero">
            <div class="hero-background-container">
                <div class="hero-bubbles-container">
                    <div class="hero-bubbles-grid">
                        <div class="hero-bubble"></div>
                        <div class="hero-bubble"></div>
                        <div class="hero-bubble"></div>
                        <div class="hero-bubble"></div>
                        <div class="hero-bubble"></div>
                        <div class="hero-bubble"></div>
                    </div>
                </div>
                <div class="hero-background pattern-zigzag-sm"></div>
            </div>
            <div class="hero-content-container section" fxLayoutAlign="start start" fxLayout.lt-md="column-reverse">
                <div class="about-hero-text-content" fxFlex="50" fxLayout="column" fxLayoutGap="32px" fxFlex.lt-md="100%">
                    <div class="hero-title" [innerHTML]="'pages.about.hero.title' | translate"></div>
                    <div class="hero-description">
                        {{ "pages.about.hero.description" | translate }}
                    </div>
                </div>
                <div fxFlex="50" fxFlex.lt-md="100%" class="hero-image">
                    <img class="hero-web-ss" src="assets/images/team.png"/>
                </div>
            </div>
        </div>

        <div class="section-container mission">
            <div class="section">
                <h1 class="text-center mb-64" [innerHTML]="'pages.about.mission.title' | translate"></h1>
                <div class="mt-16" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutGap="64px">
                    <div fxFlex="40" class="mission-image">
                        <img src="../../../assets/images/terrain.jpg"/>
                    </div>
                    <div fxFlex.lt-md="1 1 0%" fxFlex="60">
                        <div class="mission-text" fxLayoutAlign="start start" fxLayout="column" fxLayoutGap="32px"
                             fxLayoutAlign.lt-md="start start">
                            <p [innerHTML]="'pages.about.mission.description_1' | translate"></p>
                            <p [innerHTML]="'pages.about.mission.description_2' | translate"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-container values">
            <div class="section">
                <h1 class="text-center mb-64">
                    {{ "pages.about.values.title" | translate }}
                </h1>
                <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="32px">
                    <div class="values-item my-16" fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-sm="column">
                        <div fxFlex="150px" class="bubble-icon bi-blue" fxLayoutAlign="center center">
                            <fa-icon [icon]="['fad', 'handshake']" size="4x"></fa-icon>
                        </div>
                        <div class="values-text">
                            <h2>
                                {{ "pages.about.values.trust.title" | translate }}
                            </h2>
                            <p>
                                {{ "pages.about.values.trust.description" | translate }}
                            </p>
                        </div>
                    </div>
                    <div class="values-item my-16" fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-sm="column">
                        <div fxFlex="150px" class="bubble-icon bi-blue" fxLayoutAlign="center center">
                            <fa-icon [icon]="['fad', 'comments']" size="4x"></fa-icon>
                        </div>
                        <div class="values-text">
                            <h2>
                                {{ "pages.about.values.communication.title" | translate }}
                            </h2>
                            <p>
                                {{ "pages.about.values.communication.description" | translate }}
                            </p>
                        </div>
                    </div>
                    <div class="values-item my-16" fxLayout="row" fxLayoutAlign="start center" fxLayout.lt-sm="column">
                        <div fxFlex="150px" class="bubble-icon bi-blue" fxLayoutAlign="center center">
                            <fa-icon [icon]="['fad', 'shield-alt']" size="4x"></fa-icon>
                        </div>
                        <div class="values-text">
                            <h2>
                                {{ "pages.about.values.security.title" | translate }}
                            </h2>
                            <p>
                                {{ "pages.about.values.security.description" | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-container team">
            <div class="section">
                <h1 class="text-center mb-64">
                    {{ "pages.about.team.title" | translate }}
                </h1>
            </div>

            <swiper [autoplay]="{ delay: 20000 }" [pagination]="{clickable: true}" [navigation]="true" [spaceBetween]="100" [loop]="true">
                <ng-template swiperSlide>
                    <div fxLayoutAlign="start center" fxLayout.lt-lg="column">
                        <img class="team-image" src="assets/images/team/pierre.jpg">
                        <div class="team-details" fxLayout="column" fxLayoutAlign="center start">
                            <div class="team-name">
                                {{ "pages.about.team.pierre.name" | translate }}
                            </div>
                            <div class="team-title">
                                {{ "pages.about.team.pierre.title" | translate }}
                            </div>
                            <div class="team-description">
                                {{ "pages.about.team.pierre.description" | translate }}
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template swiperSlide>
                    <div fxLayoutAlign="start center" fxLayout.lt-lg="column">
                        <img class="team-image" src="assets/images/team/anne-marie.png">
                        <div class="team-details" fxLayout="column" fxLayoutAlign="center start">
                            <div class="team-name">
                                {{ "pages.about.team.anne_marie.name" | translate }}
                            </div>
                            <div class="team-title">
                                {{ "pages.about.team.anne_marie.title" | translate }}
                            </div>
                            <div class="team-description">
                                {{ "pages.about.team.anne_marie.description" | translate }}
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template swiperSlide>
                    <div fxLayoutAlign="start center" fxLayout.lt-lg="column">
                        <img class="team-image" src="assets/images/team/isabelle.png">
                        <div class="team-details" fxLayout="column" fxLayoutAlign="center start">
                            <div class="team-name">
                                {{ "pages.about.team.isabelle.name" | translate }}
                            </div>
                            <div class="team-title">
                                {{ "pages.about.team.isabelle.title" | translate }}
                            </div>
                            <div class="team-description">
                                {{ "pages.about.team.isabelle.description" | translate }}
                            </div>
                        </div>
                    </div>
                </ng-template>
                <!--                <ng-template swiperSlide>-->
                <!--                    <div fxLayoutAlign="start center" fxLayout.lt-lg="column">-->
                <!--                        <img class="team-image" src="assets/images/team/recursyve.png">-->
                <!--                        <div class="team-details" fxLayout="column" fxLayoutAlign="center start">-->
                <!--                            <div class="team-name">-->
                <!--                                {{ "pages.about.team.recursyve.name" | translate }}-->
                <!--                            </div>-->
                <!--                            <div class="team-title">-->
                <!--                                {{ "pages.about.team.recursyve.title" | translate }}-->
                <!--                            </div>-->
                <!--                            <div class="team-description">-->
                <!--                                {{ "pages.about.team.recursyve.description" | translate }}-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </ng-template>-->
            </swiper>
        </div>
        <div class="section-container story">
            <div class="section">
                <h1 class="text-center mb-64">
                    {{ "pages.about.story.title" | translate }}
                </h1>

                <mat-accordion>
                    <div class="story-timeline" fxLayout="column" fxLayoutAlign="center center">
                        <mat-expansion-panel class="ep-blue">
                            <mat-expansion-panel-header>
                                <div fxLayoutAlign="start center">
                                    <img class="story-timeline-image" src="assets/images/logos/logo-symbol-white.svg">
                                    <div class="ml-8">
                                        <div class="text-bold">2016</div>
                                        <div class="mt-4">{{ "pages.about.story.2016.title" | translate }}</div>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>
                            <div [innerHTML]="'pages.about.story.2016.description' | translate"></div>

                        </mat-expansion-panel>
                        <mat-expansion-panel class="ep-green">
                            <mat-expansion-panel-header>
                                <div fxLayoutAlign="start center">
                                    <fa-icon [icon]="['fas', 'map-marker-alt']" class="story-timeline-image"></fa-icon>
                                    <div class="ml-8">
                                        <div class="text-bold">2018</div>
                                        <div class="mt-4">{{ "pages.about.story.2018.title" | translate }}</div>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>
                            <div [innerHTML]="'pages.about.story.2018.description' | translate"></div>
                        </mat-expansion-panel>
                        <mat-expansion-panel class="ep-navy">
                            <mat-expansion-panel-header>
                                <div fxLayoutAlign="start center">
                                    <fa-icon [icon]="['fas', 'mobile']" class="story-timeline-image"></fa-icon>
                                    <div class="ml-8">
                                        <div class="text-bold">2019</div>
                                        <div class="mt-4">{{ "pages.about.story.2019.title" | translate }}</div>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>
                            <div [innerHTML]="'pages.about.story.2019.description' | translate"></div>
                        </mat-expansion-panel>
                        <mat-expansion-panel class="ep-blue">
                            <mat-expansion-panel-header>
                                <div fxLayoutAlign="start center">
                                    <fa-icon [icon]="['fas', 'city']" class="story-timeline-image pr-8"></fa-icon>
                                    <div class="ml-8">
                                        <div class="text-bold">2021</div>
                                        <div class="mt-4">{{ "pages.about.story.2021.title" | translate }}</div>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>
                            <div [innerHTML]="'pages.about.story.2021.description' | translate"></div>
                        </mat-expansion-panel>
                    </div>
                </mat-accordion>
            </div>
        </div>
        <nerri-footer></nerri-footer>
    </div>
</div>
