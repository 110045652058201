import { Directive, ElementRef, Input } from "@angular/core";

@Directive({selector: "[viewElement]"})
export class ViewElementDirective {
    @Input()
    public viewElement: string;

    constructor(private elementRef: ElementRef<HTMLElement>) { }

    public scrollIntoView() {
        this.elementRef.nativeElement.scrollIntoView({
            behavior: "smooth"
        });
    }
}
