import { NgModule } from "@angular/core";
import { AboutComponent } from "./about.component";
import { NavbarModule } from "../../components/navbar/navbar.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { FooterModule } from "../../components/footer/footer.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { SwiperModule } from "swiper/angular";
import { MatExpansionModule } from "@angular/material/expansion";

@NgModule({
    imports: [
        NavbarModule,
        FlexLayoutModule,
        TranslateModule,
        MatButtonModule,
        MatIconModule,
        FooterModule,
        FontAwesomeModule,
        SwiperModule,
        MatExpansionModule
    ],
    declarations: [AboutComponent],
    exports: [AboutComponent],
})
export class AboutModule {}
