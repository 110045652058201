import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { HomeModule } from "./pages/home/home.module";
import { AboutComponent } from "./pages/about/about.component";
import { AboutModule } from "./pages/about/about.module";
import { GuidesComponent } from "./pages/guides/guides.component";
import { GuidesModule } from "./pages/guides/guides.module";

const routes: Routes = [
    {
        path: "",
        component: HomeComponent,
    },
    {
        path: "a-propos",
        component: AboutComponent,
    },
    {
        path: "guides",
        component: GuidesComponent,
    },
    {
        path: "**",
        redirectTo: "",
    },
];

@NgModule({
    imports: [
        HomeModule,
        AboutModule,
        GuidesModule,
        RouterModule.forRoot(routes, {
            useHash: false,
            scrollPositionRestoration: "enabled",
            initialNavigation: "enabled",
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
