<div class="navbar">
    <div class="navbar-inner" fxLayoutAlign="space-between center">
        <div fxFlex="1 1 0%" fxLayoutAlign="space-between center">
            <div fxLayout="row">
                <a class="logo" [routerLink]="'/'">
                    <img [src]="'assets/images/logos/logo.svg'" />
                </a>
                <nav class="navbar-menu hide-lt-sm">
                    <a
                        class="navbar-item"
                        routerLinkActive="active"
                        [routerLink]="'/'"
                        [routerLinkActiveOptions]="{exact: true}"
                    >
                        {{ "components.navbar.home" | translate }}
                    </a>
                    <a
                        class="navbar-item"
                        routerLinkActive="active"
                        [routerLink]="'/a-propos'"
                    >
                        {{ "components.navbar.about" | translate }}
                    </a>
                    <a
                        class="navbar-item"
                        routerLinkActive="active"
                        [routerLink]="'/guides'"
                    >
                        {{ "components.navbar.guides" | translate }}
                    </a>
                </nav>
            </div>
            <div class="hide-lt-sm">
                <a
                    *ngIf="currentLang !== 'fr'"
                    class="navbar-item"
                    (click)="toggleLang()"
                >
                    {{ "components.navbar.fr" | translate }}
                </a>
                <a
                    *ngIf="currentLang === 'fr'"
                    class="navbar-item"
                    (click)="toggleLang()"
                >
                    {{ "components.navbar.en" | translate }}
                </a>
                <a
                    mat-flat-button
                    color="accent"
                    target="_blank"
                    rel="noreferrer"
                    href="https://portal.nerri.ca"
                >
                    {{ "components.navbar.login" | translate }}
                    <mat-icon>chevron_right</mat-icon>
                </a>
            </div>
        </div>
        <a class="hamburger hide-gt-xs">
            <fa-icon
                [icon]="['fal', 'bars']"
                size="2x"
                (click)="openNav()"
            ></fa-icon>
        </a>
    </div>
</div>
<div
    id="overlay-nav"
    class="overlay pattern-cross-dots-lg"
    [ngClass]="{visible: navOpen}"
>
    <a class="close-button" (click)="closeNav()">
        <fa-icon [icon]="['fal', 'times']" size="2x"></fa-icon>
    </a>

    <div class="overlay-content" fxLayout="column" fxLayoutAlign="start center">
        <a
            class="navbar-item"
            routerLinkActive="active"
            [attr.data-hover]="'components.navbar.home' | translate"
            [routerLink]="'/'"
            [routerLinkActiveOptions]="{exact: true}"
            >{{ "components.navbar.home" | translate }}</a
        >
        <a
            class="navbar-item"
            routerLinkActive="active"
            [attr.data-hover]="'components.navbar.about' | translate"
            [routerLink]="'/a-propos'"
            >{{ "components.navbar.about" | translate }}</a
        >
        <a
            class="navbar-item"
            routerLinkActive="active"
            [attr.data-hover]="'components.navbar.about' | translate"
            [routerLink]="'/guides'"
            >{{ "components.navbar.guides" | translate }}</a
        >
        <a
            *ngIf="currentLang !== 'fr'"
            class="navbar-item"
            (click)="toggleLang()"
            [attr.data-hover]="'components.navbar.fr' | translate"
        >
            {{ "components.navbar.fr" | translate }}
        </a>
        <a
            *ngIf="currentLang === 'fr'"
            class="navbar-item"
            (click)="toggleLang()"
            [attr.data-hover]="'components.navbar.en' | translate"
        >
            {{ "components.navbar.en" | translate }}
        </a>
        <div>
            <a
                mat-flat-button
                color="accent"
                target="_blank"
                rel="noreferrer"
                href="https://portal.nerri.ca"
            >
                {{ "components.navbar.login" | translate }}
                <mat-icon>chevron_right</mat-icon>
            </a>
        </div>
    </div>
</div>
