import { NgModule } from "@angular/core";
import { BrowserTransferStateModule } from "@angular/platform-browser";
import { AppModule } from "./app.module";
import { AppComponent } from "./app.component";
import { TranslatesBrowserModule } from "./translates/translates-browser/translates-browser.module";
import { AkitaNgDevtools } from "@datorama/akita-ngdevtools";
import { AkitaNgRouterStoreModule } from "@datorama/akita-ng-router-store";
import { environment } from "../environments/environment";

@NgModule({
    imports: [
        AppModule,
        BrowserTransferStateModule,
        TranslatesBrowserModule,
        environment.production ? [] : AkitaNgDevtools.forRoot(),
        AkitaNgRouterStoreModule,
    ],
    bootstrap: [AppComponent],
})
export class AppBrowserModule {}
